import { Options } 	from './types'
import { Loader } from "./models/Loader"

export default {
	install: (app, options: Options) => {
		if (options && options.disabled === true) return

		const loader = new Loader(options)

		app.config.globalProperties.$loader = loader
	}
}