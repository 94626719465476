export default {
	namespaced: true,
	state: {
		campaigns: [1],
	},
	mutations: {
		openAccordion(state, index) {
			if (!state.campaigns.includes(index)) state.campaigns.push(index)
		},

		closeAccordion(state, index) {
			const id = state.campaigns.indexOf(index);
			state.campaigns.splice(id, 1);
		},

	},
	actions: {
		openAccordion({ commit }, index) {
			commit('openAccordion', index)
		},

		closeAccordion({ commit }, index) {
			commit('closeAccordion', index)
		}
	}
}
