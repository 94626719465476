export default {
	namespaced: true,
	state: {
		fullName: null,
		email: null,
		phoneNumber: null,
		biography: null,
		gdpr: null
	},
	getters: {
		gdprError (state) {
			return state.gdpr === 'Áno' && (!state.gdprUrl && !state.gdprFile) ? true : false
		},

		getBiography(state) {
			return state.biography === 'Áno' ? true : false
		},
	},
	mutations: {
		clearState(state) {
			state.fullName = null
			state.email = null
			state.phoneNumber = null
			state.biography = null
			state.gdpr = null
		},

		setFullName(state, value) {
			state.fullName = value
		},

		setEmail(state, value) {
			state.email = value
		},

		setPhoneNumber(state, value) {
			state.phoneNumber = value
		},

		setBiography(state, value) {
			state.biography = value
		},

		setGdpr(state, value) {
			state.gdpr = value
			if (value == 'Nie') {
				delete state.gdprFile
				delete state.gdprUrl
			}
		},

		setFile(state, file) {
			state.gdprFile = file
			delete state.gdprUrl
		},

		setUrl(state, value) {
			state.gdprUrl = value
			delete state.gdprFile
		},

		removeFile(state) {
			delete state.gdprFile
		}
	},
	actions: {
		clearState({ commit }) {
			commit('clearState')
		},

		setFullName({ commit }, value) {
			commit('setFullName', value)
		},

		setEmail({ commit }, value) {
			commit('setEmail', value)
		},

		setPhoneNumber({ commit }, value) {
			commit('setPhoneNumber', value)
		},

		setBiography({ commit }, value) {
			commit('setBiography', value)
		},

		setGdpr({ commit }, value) {
			commit('setGdpr', value)
		},

		setFile({ commit }, file) {
			commit('setFile', file)
		},

		setUrl({ commit }, value) {
			commit('setUrl', value)
		},

		removeFile({ commit }) {
			commit('removeFile')
		}
	}
}
