import { App }	from 'vue'
import listAccordionState 	from './modules/list-accordion-state'

export default {
	install: (app: App) => {
		const store = app.config.globalProperties.$store
		if (!store)
			return console.warn('[w/auth] Could not initialize. App does not have vuex store added.')

		store.registerModule(['accordions', 'list'], listAccordionState)
	}
}