import { App }	from 'vue'
import detailAccordionState 	from './modules/detail-accordion-state'

export default {
	install: (app: App) => {
		const store = app.config.globalProperties.$store
		if (!store)
			return console.warn('[w/auth] Could not initialize. App does not have vuex store added.')

		store.registerModule(['accordions', 'detail'], detailAccordionState)
	}
}