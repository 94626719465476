import { loadingController } 	from '@ionic/vue'
import { ref, Ref }				from 'vue'
import { Options } 				from '../types'

export class Loader {
	public loading: Ref 			= ref(false)
	private enableUiLoader: boolean
	private loader //dorobit typ

	constructor(options: Options) {
		this.enableUiLoader = options.enableUiLoader
	}

	public async start() {
		this.loading.value = true

		if (this.enableUiLoader) {

			this.loader = await loadingController.create({
				showBackdrop: true,
				animated: true,
			}).then(a => {
				a.present().then(() => {
					if (!this.loading) {
						a.dismiss()
					}
				});
			})
		}
	}

	public stop() {
		this.loading.value = false

		if (this.enableUiLoader) {
			loadingController.dismiss()
		}
	}
}