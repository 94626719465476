import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import store from '@/plugins/app/_config/store'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		redirect: '/auth/login'
	},
	{
		path: '/auth/',
		component: () => import('@/plugins/app/_layout/auth.layout.vue'),
		children: [
			{
				path: '',
				redirect: '/auth/login'
			},
			{
				path: 'login',
				name: 'Login',
				component: () => import('@/plugins/hr@auth/login/login.vue'),
				meta: {requiresAuth: false}
			},
			{
				path: 'forgot',
				name: 'Forgot password',
				component: () => import('@/plugins/hr@auth/forgot-password/forgot-password.vue'),
				meta: {requiresAuth: false}
			},
			{
				path: 'reset-password/:email?/:code?',
				name: 'Reset password',
				component: () => import('@/plugins/hr@auth/reset-password/reset-password.vue'),
				meta: {requiresAuth: false}
			},
			{
				path: 'register',
				name: 'Register',
				component: () => import('@/plugins/hr@auth/register/register.vue'),
				meta: {requiresAuth: false},
			},
			{
				path: 'activate/:email/:code?',
				name: 'Activate',
				component: () => import('@/plugins/hr@auth/activate/activate.vue'),
				meta: {requiresAuth: false},
			}
		]
	},
	{
		path: '/app/',
		component: () => import('@/plugins/app/_layout/app.layout.vue'),
		children: [
			{
				path: '',
				redirect: '/app/campaigns'
			},
			{
				path: 'campaigns',
				name: 'Campaigns',
				component: () => import('@/plugins/hr@campaign/list/list.vue'),
				meta: {requiresAuth: true}
			},
			{
				path: 'campaigns/:campaignId',
				name: 'Campaign detail',
				component: () => import('@/plugins/hr@campaign/detail/detail.vue'),
				meta: {requiresAuth: true}
			},
			{
				path: 'campaigns/:campaignId/applicants/:applicantId',
				name: 'Applicant detail',
				component: () => import('@/plugins/hr@campaign-applicants/detail/detail.vue'),
				meta: {requiresAuth: true}
			},
			{
				path: 'create-campaign/:campaignId/',
				name: 'Create campaign',
				component: () => import('@/plugins/hr@campaign/create/_layout/create.layout.vue'),
				meta: {requiresAuth: true},
				children: [
					{
						path: '',
						redirect: '/app/campaigns/:campaignId/1'
					},
					{
						path: '1',
						name: 'Create campaign - Step 1',
						component: () => import('@/plugins/hr@campaign/create/step-1/step-1.vue'),
						meta: {requiresAuth: true}
					},
					{
						path: '2',
						name: 'Create campaign - Step 2',
						component: () => import('@/plugins/hr@campaign/create/step-2/step-2.vue'),
						meta: {requiresAuth: true}
					},
					{
						path: '3',
						name: 'Create campaign - Step 3',
						component: () => import('@/plugins/hr@campaign/create/step-3/step-3.vue'),
						meta: {requiresAuth: true}
					},
					{
						path: '4',
						name: 'Create campaign - Step 4',
						component: () => import('@/plugins/hr@campaign/create/step-4/step-4.vue'),
						meta: {requiresAuth: true}
					}
				]
			},
			{
				path: 'settings',
				name: 'Settings',
				component: () => import('@/plugins/hr@settings/settings.vue'),
				meta: {requiresAuth: true},
			}
		]
	},
	{
		path: '/upload/',
		component: () => import('@/plugins/app/_layout/upload.layout.vue'),
		children: [
			{
				path: 'cv/:applicantId',
				name: 'Upload CV',
				component: () => import('@/plugins/hr@upload-cv/upload-cv.vue'),
				meta: {requiresAuth: false}
			}
		]
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/auth/login'
	}
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	const isLoggedIn = store.getters['auth/isLoggedIn']

	if (to.meta.requiresAuth === true && isLoggedIn) {
		if (isLoggedIn)
			return next()
		else
			return next({name: 'Login'})
	} else if (to.meta.requiresAuth === false) {
		if (!isLoggedIn)
			return next()
		else
			return next()
	} else {
		return next({name: 'Login'})
	}
})

export default router
