export default {
	namespaced: true,
	state: {
		process: [],
		applicants: [0]
	},
	mutations: {
		openProcessAccordion(state, index) {
			if (!state.process.includes(index)) state.process.push(index)
		},

		closeProcessAccordion(state, index) {
			const id = state.process.indexOf(index);
			state.process.splice(id, 1);
		},

		openApplicantsAccordion(state, index) {
			if (!state.applicants.includes(index)) state.applicants.push(index)
		},

		closeApplicantsAccordion(state, index) {
			const id = state.applicants.indexOf(index);
			state.applicants.splice(id, 1);
		}

	},
	actions: {
		openProcessAccordion({ commit }, index) {
			commit('openProcessAccordion', index)
		},

		closeProcessAccordion({ commit }, index) {
			commit('closeProcessAccordion', index)
		},

		openApplicantsAccordion({ commit }, index) {
			commit('openApplicantsAccordion', index)
		},

		closeApplicantsAccordion({ commit }, index) {
			commit('closeApplicantsAccordion', index)
		}

	}
}
