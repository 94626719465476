<template>
	<div class="ion-padding">
		<div class="wrapper">
			<ProgressSpinner />
			<!-- <ion-spinner name="crescent"></ion-spinner> -->
		</div>
	</div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
export default {
	components: {
		ProgressSpinner
	}
}
</script>

<style lang="css" scoped>
.wrapper {
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 739px) {
	.wrapper {
		height: calc(100vh - 250px)
	}
}
</style>