import { createStore } from 'vuex'

export default createStore({
	modules: {
		create: {},
		accordions: {}
	},
	state: {
		showLinkDialog: false
	},
	mutations: {
		// Denis: Toto vyjebat odtialto, spravte to tak ze si poslete nejaky url query param na detail a podla toho to zobrazte
		setShowLinkDialog(state, value) {
			state.showLinkDialog = value
		}
	}
})
