export default {
	namespaced: true,
	state: {
		questions: []
	},
	getters: {
		isQuestionFilled(state) {
			let result = false

			const filteredQuestions = state.questions.filter((question) => !question.open_question)

			filteredQuestions.forEach((question) => {
				let isQuestion = true
				let option = true
				let answer = true
				
				if (!question.question) isQuestion = false
				if (question.options) {
					question.options.forEach((option) => {
						if (!option.text) option = false
						if (!option.option) answer = false
					})
				}

				if (isQuestion && option && answer) return result = true
			})

			return result
		},

		sortedQuestions(state) {
			const dupQuestions = JSON.parse(JSON.stringify(state.questions))
			dupQuestions.forEach((question, index) => {
				question.sort_order = index
				if (question.options?.length) {
					question.options.forEach((option) => {
						if (option.option === 'Vyhovujúci') option.option = 'suitable'
						if (option.option === 'Akceptovateľný') option.option = 'acceptable'
						if (option.option === 'Nevyhovujúci') option.option = 'unsatisfying'
					})
				}
			})
			return dupQuestions
		},

		validAnswers(state) {
			let isValid = true
			state.questions.forEach((question) => {
				if (question.open_question) return
				if (question.options.every(option => option.option === 'Nevyhovujúci')) return isValid = false
			})
			return isValid
		}
	},
	mutations: {
		clearState(state) {
			state.questions = []
		},

		updateQuestions(state, value) {
			state.questions = value
		},

		updateBeQuestions(state, value) {
			const edited = JSON.parse(JSON.stringify(value))
			edited.forEach((question) => {
				if (question.options) {
					question.options.forEach((option) => {
						if (option.option === 'suitable') option.option = 'Vyhovujúci'
						if (option.option === 'acceptable') option.option = 'Akceptovateľný'
						if (option.option === 'unsatisfying') option.option = 'Nevyhovujúci'
					})
				}
			})
			state.questions = edited
		},

		addOpenQuestion(state) {
			state.questions.push({
				open_question: true,
				question: ''
			})
		},
		
		addQuestionWithOptions(state) {
			state.questions.push({
				open_question: false,
				question: '',
				options: [
					{
						text: '',
						option: ''
					},
					{
						text: '',
						option: ''
					}
				]
			})
		},

		duplicateQuestion(state, questionId) {
			const duplicate = JSON.parse(JSON.stringify(state.questions))
			state.questions.splice(questionId + 1, 0, duplicate[questionId])
		},

		removeQuestion(state, questionId) {
			state.questions.splice(questionId, 1)
		},

		setQuestionTitle(state, { newValue, questionId }) {
			state.questions[questionId].question = newValue
		},

		addOption(state, questionId) {
			state.questions[questionId].options.push({
				text: '',
				option: ''
			})
		},

		deleteOption(state,{ questionId, optionId }) {
			console.log(questionId)
			state.questions[questionId].options.splice(optionId, 1)
		},

		setOptionValue(state, { newValue, questionId, optionId }) {
			state.questions[questionId].options[optionId].text = newValue
		},

		setOptionAnswer(state, { newValue, questionId, optionId }) {
			state.questions[questionId].options[optionId].option = newValue
		}
	},

	actions: {
		clearState({ commit }) {
			commit('clearState')
		},

		updateQuestions({ commit }, value) {
			commit('updateQuestions', value)
		},

		updateBeQuestions({ commit }, value) {
			commit('updateBeQuestions', value)
		},

		addOpenQuestion({ commit }) {
			commit('addOpenQuestion')
		},

		addQuestionWithOptions({ commit }) {
			commit('addQuestionWithOptions')
		},

		duplicateQuestion({ commit }, questionId) {
			commit('duplicateQuestion', questionId)
		},

		removeQuestion({ commit }, questionId) {
			commit('removeQuestion', questionId)
		},

		setQuestionTitle({ commit }, { newValue, questionId }) {
			commit('setQuestionTitle', { newValue, questionId })
		},

		addOption({ commit }, questionId) {
			commit('addOption', questionId)
		},

		deleteOption({ commit }, { questionId, optionId }) {
			commit('deleteOption', { questionId, optionId })
		},

		setOptionValue({ commit }, { newValue, questionId, optionId }) {
			commit('setOptionValue', { newValue, questionId, optionId })
		},

		setOptionAnswer({ commit }, { newValue, questionId, optionId }) {
			commit('setOptionAnswer', { newValue, questionId, optionId })
		}
	}
}
