export default {
	namespaced: true,
	state: {
		title: '',
		description: '',
		companyName: '',
		processSteps: [
			{
				name: 'Predbežný výber'
			},
			{
				name: ''
			}
		]
	},
	
	getters: {
		sortedSteps(state) {
			const dupSteps = JSON.parse(JSON.stringify(state.processSteps))
			dupSteps.forEach((step, index) => {
				step.sort_order = index
			})
			return dupSteps
		}
	},

	mutations: {
		clearState(state) {
			state.title = '',
			state.description = '',
			state.processSteps = [
				{
					name: 'Predbežný výber'
				},
				{
					name: ''
				}
			]
		},

		updateSteps(state, value) {
			state.processSteps = value
		},

		setTitle(state, value) {
			state.title = value
		},

		setDescription(state, value) {
			state.description = value
		},

		setCompanyName(state, value) {
			state.companyName = value
		},

		addStep(state) {
			state.processSteps.push({name: ''})
		},

		removeStep(state, id) {
			if (id > 0) state.processSteps.splice(id, 1)
		},

		setStep(state, { newValue, index }) {
			state.processSteps[index].name = newValue
		}
	},

	actions: {
		clearState({ commit }) {
			commit('clearState')
		},

		updateSteps({ commit }, value) {
			commit('updateSteps', value)
		},

		setTitle({ commit }, value) {
			commit('setTitle', value)
		},

		setDescription({ commit }, value) {
			commit('setDescription', value)
		},

		setCompanyName({ commit }, value) {
			commit('setCompanyName', value)
		},

		addStep({ commit }) {
			commit('addStep')
		},

		removeStep({ commit }, id) {
			commit('removeStep', id)
		},

		setStep({ commit }, { newValue, index }) {
			commit('setStep', { newValue, index })
		}
	}
}
