export default {
	namespaced: true,
	state: {
		analysis: null,
		keywords: null
	},
	mutations: {
		clearState(state) {
			state.analysis = null
			state.keywords = null
		},

		setKeywords(state, value) {
			state.keywords = value
		},
		setAnalysis(state, value) {
			state.analysis = value
		}
	},
	actions: {
		clearState({ commit }) {
			commit('clearState')
		},

		setKeywords({ commit }, value) {
			commit('setKeywords', value)
		},

		setAnalysis({ commit }, value) {
			commit('setAnalysis', value)
		}
	}
}
