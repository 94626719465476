import { createApp } 	from 'vue'
import App 				from './App.vue'
import router 			from './router'
import store 			from '@/plugins/app/_config/store'
import createSteps		from '@/plugins/hr@campaign/create/_store/index'
import detailAccordionState from '@/plugins/hr@campaign/detail/_store/index'
import listAccordionState from '@/plugins/hr@campaign/list/_store/index'
import { IonicVue } 	from '@ionic/vue'

import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

import draggable from 'vuedraggable'

import w from '@/plugins/w/w'
import { Options as WOptions } from '@/plugins/w/w/types'

import primeVue from '@/plugins/w/primevue'

import '../_theme/theme.css'

const options: WOptions = {
	i18n: {
		languages: [
			{ title: 'Slovenský', flag: 'sk', value: 'sk' }
		],
		defaultLanguage: 'sk'
	},
	ionic: {
		disabled: true
	},
	moxios: {
		routes: {
			'GET v1/applicants/(id)': true,
			'POST v1/applicants/(id)': true,
			'DELETE v1/applicants/(id)': true,

			'GET v1/campaigns/(id)': true,
			'POST v1/campaigns/(id)': true,
			'DELETE v1/campaigns/(id)': true,
			'GET v1/campaigns': true,
			'POST v1/campaigns': true,

			'POST v1/messages': true
		}
	},
	loader: {
		enableUiLoader: true
	}
}

const app = createApp(App)
	.use(IonicVue)
	.use(router)
	.use(store)
	.use(w, options)
	.use(primeVue)
	.use(createSteps)
	.use(detailAccordionState)
	.use(listAccordionState)
	.component('draggable', draggable)
	.mount('#app')


// @ts-expect-error
export const $t = app.$i18n.t