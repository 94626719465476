import { App }	from 'vue'
import jobPosition 	from './modules/job-position-step'
import questionsAndOptions 	from './modules/questions-and-options-step'
import weWillRequire 	from './modules/we-will-require'
import analysis 	from './modules/analysis'

export default {
	install: (app: App) => {
		const store = app.config.globalProperties.$store
		if (!store)
			return console.warn('[w/auth] Could not initialize. App does not have vuex store added.')

		store.registerModule(['create', 'jobPosition'], jobPosition)
		store.registerModule(['create', 'questionsAndOptions'], questionsAndOptions)
		store.registerModule(['create', 'weWillRequire'], weWillRequire)
		store.registerModule(['create', 'analysis'], analysis)
	}
}