<template>
	<div>
		<div v-if="!items">
			<Z-spinner />
		</div>

		<div v-if="typeof items === 'object'">
		<div
			v-if="items && !Object.keys(items).length"
			class="ion-padding loadable-message"
		>
			<p>{{ messageEmpty }}</p>
		</div>
		<div
			v-else-if="items && items.error"
			class="ion-padding loadable-message"
		>
			<p>{{ messageError }} {{ utils.parseAndTranslate(items.error) }}</p>
		</div>
		<slot v-else-if="items && Object.keys(items).length"></slot>
		</div>

		<div v-else-if="Array.isArray(items)">
		<div v-if="items && !items.length" class="ion-padding loadable-message">
			<p>{{ messageEmpty }}</p>
		</div>
		<slot v-else-if="items && items.length"></slot>
		</div>

		<div v-else-if="items == true">
		<slot></slot>
		</div>
	</div>
</template>

<script>
import ZSpinner from './_components/z-spinner.vue'
import utils 	from '@/plugins/w/toast/utils/toast.utils'

export default {
	props: {
		items: {
			type: [Object, Array, Boolean],
			default: null,
		},

		messageEmpty: {
			type: String,
			default: 'No items found.',
		},

		messageError: {
			type: String,
			default: 'Error. We could not load requested data.',
		},
	},

	components: {
		ZSpinner
	},

	data() {
		return {
			utils
		}
	},
}
</script>

<style scoped>
.loadable-message {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loadable-message p {
	margin: 0 20px;
	text-align: center;
	color: rgba(0, 0, 0, 0.5);
}

</style>
